import React from 'react';
import './FAQ.css'; // Ensure your CSS file has the updated styles

const FAQ = () => {
    return (
        <div className="faq-container">
            <h1>Frequently Asked Questions</h1>
            <div className="faq-item">
                <h2>What is Zeus Analytics?</h2>
                <p>Zeus Analytics is a website designed to assist with making educated sports bets. We partner with different cappers in the space to know what information they use to make their picks and we aggregate that data for everyone in one concise location.</p>
            </div>
            <div className="faq-item">
                <h2>I subscribed to Zeus Analytics. What do I get?</h2>
                <p>Once you subscribe, you get access to all our tools and predictions, updated daily. We also send out our predictions to our subscribers via DubClub. We are tirelessly working to add more tools every week.</p>
            </div>
            <div className="faq-item">
                <h2>I subscribed to Zeus_Analytics but it is still redirecting me to the checkout page?</h2>
                <p>We are partnered with DubClub, who is partnered with Stripe to keep all your banking/credit information secure. When subscribing, there is sometimes a transmission delay up to an hour until it registers you into our system. If this happens to you, reach out to us on X so we can assist you.</p>
            </div>
            <div className="faq-item">
                <h2>How often is the data updated?</h2>
                <p>As of Sept 29th, 2024 (the last time this FAQ was updated), we have two sports available. MLB data is updated automatically every day and the NFL data is updated week by week.</p>
            </div>
            <div className="faq-item">
                <h2>Why can't I see the updates?</h2>
                <p>We work very hard to bring you new content weekly. Sometimes when updating the website, your browser will remember old data. To reload the page with new data, type Ctrl + Shift + R on Windows and CMD + Shift + R on Mac. This can also be solved by using an Incognito tab.</p>
            </div>
        </div>
    );
};

export default FAQ;
