// src/NFLHitRates.jsx

import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './NFLHitRates.css';

// Mapping of market names for user-friendly display
const marketNameMap = {
  player_pass_attempts: 'Pass Attempts',
  player_pass_completions: 'Pass Completions',
  player_pass_interceptions: 'Pass Interceptions',
  player_pass_tds: 'Pass TDs',
  player_pass_yds: 'Pass Yards',
  player_receptions: 'Receptions',
  player_reception_yds: 'Reception Yards',
  player_rush_attempts: 'Rush Attempts',
  player_rush_yds: 'Rush Yards',
  player_anytime_td: 'Anytime TD',
  player_tds_over: 'TDs',
};

// Function to combine Outcome, Point, and Market
const getPropText = (outcome, point, market) => {
  const marketName = marketNameMap[market] || market;
  if (point) {
    return `${outcome} ${point} ${marketName}`;
  }
  return `${outcome} ${marketName}`;
};

// Function to dynamically generate color based on hit rate (expects rate between 0 and 1)
const getColorForRate = (rate) => {
  if (rate > 0.5) {
    return `rgb(144, 238, 144)`; // green for high hit rates
  } else if (rate > 0) {
    return `rgb(255, 165, 0)`; // orange for mid hit rates
  } else {
    return `rgb(255, 99, 71)`; // red for low hit rates
  }
};

const NFLHitRates = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [sortField, setSortField] = useState('hit_rate_last_5');
  const [sortDirection, setSortDirection] = useState('desc');
  const [marketsFilter, setMarketsFilter] = useState(Object.keys(marketNameMap));
  const [sportsbooksFilter, setSportsbooksFilter] = useState([]);
  const [availableSportsbooks, setAvailableSportsbooks] = useState([]);
  const [hitRateFilter, setHitRateFilter] = useState('All');
  const [isAuthenticated, setIsAuthenticated] = useState(null); // Subscription check
  const lastElementRef = useRef(null);

  // Subscription check logic
  const checkSubscriptionStatus = async (token) => {
    try {
      const response = await axios.get('/api/check-subscription/', {
        params: { token }
      });
      const subscriptions = response.data;
      const currentTime = new Date().toISOString();

      const isSubscribed = subscriptions.some(subscription =>
        subscription.price.product.capper.id === 320906 &&
        subscription.paid_until > currentTime
      );

      return isSubscribed;
    } catch (error) {
      console.error('Error checking subscription status:', error);
      return false;
    }
  };

  useEffect(() => {
    axios.get('/profile/')
      .then(response => {
        const { access_token } = response.data;
        checkSubscriptionStatus(access_token).then(isSubscribed => {
          setIsAuthenticated(isSubscribed);  // Set the authenticated state
          if (isSubscribed) {
            fetchData(); // Fetch data if the user is subscribed
          } else {
            window.location.href = 'https://dubclub.win/r/Zeus_Analytics/'; // Redirect if not subscribed
          }
        });
      })
      .catch(error => {
        console.error('Error fetching profile data:', error);
        window.location.href = 'https://zeusanalytics.org/accounts/dubclub/login/'; // Redirect if there’s an error fetching the profile
      });
  }, []);

  // Fetch data from the API
  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get('/api/nfl-hit-rates', {
        params: {
          page: page,
          sort: sortField,
          direction: sortDirection
        }
      });
      const responseData = response.data.results;

      // Extract all unique sportsbooks from the data
      const sportsbooksSet = new Set(responseData.map(item => item.sportsbook));
      const allSportsbooks = Array.from(sportsbooksSet);

      setData(prevData => [...prevData, ...responseData]);
      setAvailableSportsbooks(prevAvailableSportsbooks => Array.from(new Set([...prevAvailableSportsbooks, ...allSportsbooks])));
      if (page === 1) {
        setSportsbooksFilter(allSportsbooks); // Default: all sportsbooks selected
      }
      setHasMore(response.data.next !== null);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching NFL hit rates data:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      fetchData();
    }
  }, [page, sortField, sortDirection, isAuthenticated]);

  const loadMore = () => {
    if (hasMore) {
      setPage(prevPage => prevPage + 1);
    }
  };

  const handleSort = (field) => {
    const direction = sortField === field && sortDirection === 'asc' ? 'desc' : 'asc';
    setSortField(field);
    setSortDirection(direction);
    setPage(1);
    setData([]);
  };

  const handleMarketChange = (market) => {
    if (marketsFilter.includes(market)) {
      setMarketsFilter(marketsFilter.filter(m => m !== market));
    } else {
      setMarketsFilter([...marketsFilter, market]);
    }
  };

  const handleSportsbookChange = (sportsbook) => {
    if (sportsbooksFilter.includes(sportsbook)) {
      setSportsbooksFilter(sportsbooksFilter.filter(s => s !== sportsbook));
    } else {
      setSportsbooksFilter([...sportsbooksFilter, sportsbook]);
    }
  };

  const handleHitRateChange = (rate) => {
    setHitRateFilter(rate);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore && !loading) {
        loadMore();
      }
    }, { threshold: 1.0 });

    if (lastElementRef.current) {
      observer.observe(lastElementRef.current);
    }

    return () => {
      if (lastElementRef.current) {
        observer.unobserve(lastElementRef.current);
      }
    };
  }, [loading, hasMore]);

  // Updated filtering to handle hit rate correctly
  const filteredData = data.filter(item => {
    const marketIncluded = marketsFilter.includes(item.market);
    const sportsbookIncluded = sportsbooksFilter.includes(item.sportsbook);
    const hitRateLast5Percent = (item.hit_rate_last_5 / 5) * 100;
    const hitRateIncluded = hitRateFilter === 'All' || hitRateLast5Percent === hitRateFilter;
    return marketIncluded && sportsbookIncluded && hitRateIncluded;
  });

  if (isAuthenticated === null) {
    return <div>Loading...</div>; // Display loading until the authentication status is determined
  }

  return (
    <div className="nfl-hit-rates">
      <h1>NFL Hit Rates</h1>

      {/* Filters in a bar with dropdowns */}
      <div className="nfl-filter-bar">
        <div className="nfl-dropdown">
          <button className="nfl-dropbtn">Markets</button>
          <div className="nfl-dropdown-content">
            {Object.keys(marketNameMap).map((market) => (
              <label key={market}>
                <input
                  type="checkbox"
                  checked={marketsFilter.includes(market)}
                  onChange={() => handleMarketChange(market)}
                />
                {marketNameMap[market]}
              </label>
            ))}
          </div>
        </div>

        <div className="nfl-dropdown">
          <button className="nfl-dropbtn">Sportsbooks</button>
          <div className="nfl-dropdown-content">
            {availableSportsbooks.map((sportsbook) => (
              <label key={sportsbook}>
                <input
                  type="checkbox"
                  checked={sportsbooksFilter.includes(sportsbook)}
                  onChange={() => handleSportsbookChange(sportsbook)}
                />
                {sportsbook}
              </label>
            ))}
          </div>
        </div>

        <div className="nfl-dropdown">
          <button className="nfl-dropbtn">Hit Rate</button>
          <div className="nfl-dropdown-content">
            <label>
              <input
                type="radio"
                name="hitRate"
                checked={hitRateFilter === 'All'}
                onChange={() => handleHitRateChange('All')}
              />
              All
            </label>
            {[20, 40, 60, 80, 100].map((rate) => (
              <label key={rate}>
                <input
                  type="radio"
                  name="hitRate"
                  checked={hitRateFilter === rate}
                  onChange={() => handleHitRateChange(rate)}
                />
                {rate}%
              </label>
            ))}
          </div>
        </div>
      </div>

      <div className="nfl-table-container">
        <table className="nfl-modern-table">
          <thead>
            <tr>
              <th onClick={() => handleSort('name')}>Player Name {sortField === 'name' && (sortDirection === 'asc' ? '↑' : '↓')}</th>
              <th>Prop</th>
              <th onClick={() => handleSort('odds')}>Odds {sortField === 'odds' && (sortDirection === 'asc' ? '↑' : '↓')}</th>
              <th onClick={() => handleSort('sportsbook')}>Sportsbook {sortField === 'sportsbook' && (sortDirection === 'asc' ? '↑' : '↓')}</th>
              <th onClick={() => handleSort('hit_rate_last_5')}>Last 5 Hits {sortField === 'hit_rate_last_5' && (sortDirection === 'asc' ? '↑' : '↓')}</th>
              <th onClick={() => handleSort('hit_rate_last_10')}>Last 10 Hits {sortField === 'hit_rate_last_10' && (sortDirection === 'asc' ? '↑' : '↓')}</th>
              <th onClick={() => handleSort('hit_rate_season')}>Season Hits {sortField === 'hit_rate_season' && (sortDirection === 'asc' ? '↑' : '↓')}</th>
            </tr>
          </thead>
          <tbody>
            {loading && data.length === 0 ? (
              <tr><td colSpan="7">Loading...</td></tr>
            ) : (
              filteredData.map((row, index) => {
                const hitRateLast5Rate = row.hit_rate_last_5 / 5;
                const hitRateLast10Rate = row.hit_rate_last_10 / 10;
                let seasonHits, seasonTotal, hitRateSeasonRate;
                if (typeof row.hit_rate_season === 'string') {
                  [seasonHits, seasonTotal] = row.hit_rate_season.split('/').map(Number);
                  hitRateSeasonRate = seasonHits / seasonTotal;
                } else {
                  seasonHits = row.hit_rate_season;
                  seasonTotal = 1; // Avoid division by zero
                  hitRateSeasonRate = 1;
                }
                return (
                  <tr key={index}>
                    <td>{row.name}</td>
                    <td>{getPropText(row.outcome, row.point, row.market)}</td>
                    <td>{row.odds.startsWith('-') ? row.odds : `+${row.odds}`}</td>
                    <td>{row.sportsbook}</td>
                    <td style={{ backgroundColor: getColorForRate(hitRateLast5Rate) }}>
                      {row.hit_rate_last_5}/5
                    </td>
                    <td style={{ backgroundColor: getColorForRate(hitRateLast10Rate) }}>
                      {row.hit_rate_last_10}/10
                    </td>
                    <td style={{ backgroundColor: getColorForRate(hitRateSeasonRate) }}>
                      {row.hit_rate_season}
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
        {loading && data.length > 0 && <div>Loading...</div>}
        <div ref={lastElementRef} />
      </div>
    </div>
  );
};

export default NFLHitRates;
