import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useNavigate } from 'react-router-dom';
import {
  FaBaseballBall,
  FaBasketballBall,
  FaFootballBall,
  FaHockeyPuck,
  FaTools,
} from 'react-icons/fa';
import Home from './components/Home';
import Login from './components/Login';
import ExitVelocityTracker from './components/ExitVelocityTracker';
import Settings from './components/Settings';
import WhosHot from './components/WhosHot';
import StartingPitcherStats from './components/StartingPitcherStats';
import DailyLineupStats from './components/DailyLineupStats';
import Scores from './components/Scores';
import ArbitrageCalculator from './components/ArbitrageCalculator';
import OddsConverter from './components/OddsConverter';
import MlbOdds from './components/mlbodds';
import ParkFactors from './components/ParkFactors';
import MLBDefinitions from './components/MLBDefinitions';
import InningFirstStats from './components/InningFirstStats';
import TopHRPredictions from './components/TopHRPredictions';
import DefensiveStats from './components/DefensiveNFLStats';
import TopTDPredictions from './components/TopTDPredictions';
import TopTDLongshots from './components/TopTDLongshots';
import CoverageMatrix from './components/CoverageMatrix';
import NflHitRates from './components/NflHitRates';
import FAQ from './components/FAQ';
import './App.css';

function App() {
  const [favoriteTeam, setFavoriteTeam] = useState(
    localStorage.getItem('favoriteTeam') || 'none'
  );
  const [menuActive, setMenuActive] = useState(false);
  const [mlbActive, setMlbActive] = useState(false);
  const [nbaActive, setNbaActive] = useState(false);
  const [nflActive, setNflActive] = useState(false);
  const [nhlActive, setNhlActive] = useState(false);
  const [toolsActive, setToolsActive] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const team = {};
    const defaultColor = '#000080';
    document.documentElement.style.setProperty('--background-color', '#F2F2F2');
    document.documentElement.style.setProperty('--text-color', team.color || defaultColor);
  }, [favoriteTeam]);

  useEffect(() => {
    fetch('/api/auth/status')
      .then((response) => response.json())
      .then((data) => {
        setIsAuthenticated(data.isAuthenticated);
      })
      .catch((error) => console.error('Error fetching auth status:', error));
  }, []);

  const handleMenuItemClick = () => {
    setMenuActive(false);
    setMlbActive(false);
    setNbaActive(false);
    setNflActive(false);
    setNhlActive(false);
    setToolsActive(false);
  };

  const handleComingSoonClick = (e) => {
    e.preventDefault();
    alert('This feature is coming soon!');
  };

  return (
    <Router>
      <div className="App">
        <Navbar
          menuActive={menuActive}
          setMenuActive={setMenuActive}
          mlbActive={mlbActive}
          setMlbActive={setMlbActive}
          nbaActive={nbaActive}
          setNbaActive={setNbaActive}
          nflActive={nflActive}
          setNflActive={setNflActive}
          nhlActive={nhlActive}
          setNhlActive={setNhlActive}
          toolsActive={toolsActive}
          setToolsActive={setToolsActive}
          handleComingSoonClick={handleComingSoonClick}
          isAuthenticated={isAuthenticated}
          setIsAuthenticated={setIsAuthenticated}
        />
        {/* Mobile Menu */}
        <div className={`menu${menuActive ? ' active' : ''}`}>
          <button onClick={() => setMlbActive(!mlbActive)}>
            <FaBaseballBall className="icon" /> MLB
          </button>
          <div className={`submenu${mlbActive ? ' active' : ''}`}>
            <Link to="/mlb/the-script" onClick={handleMenuItemClick}>
              The Script
            </Link>
            <Link to="/mlb/exit-velocity-tracker" onClick={handleMenuItemClick}>
              Exit Velocity Tracker
            </Link>
            <Link to="/mlb/whos-hot" onClick={handleMenuItemClick}>
              Who's Hot
            </Link>
            <Link to="/mlb/starting-pitcher-stats" onClick={handleMenuItemClick}>
              Starting Pitcher Stats
            </Link>
            <Link to="/mlb/daily-lineup-stats" onClick={handleMenuItemClick}>
              Daily Lineup Stats
            </Link>
            <Link to="/mlb/inning-first-stats" onClick={handleMenuItemClick}>
              1st Inning Stats
            </Link>
            <Link to="/mlb/park-factors" onClick={handleMenuItemClick}>
              Park Factors
            </Link>
            <Link to="/mlb/scores" onClick={handleMenuItemClick}>
              Scores
            </Link>
            <Link to="/mlb/definition" onClick={handleMenuItemClick}>
              Definitions
            </Link>
          </div>
          <button onClick={() => setNbaActive(!nbaActive)}>
            <FaBasketballBall className="icon" /> NBA
          </button>
          <div className={`submenu${nbaActive ? ' active' : ''}`}>
            <Link to="#" onClick={(e) => handleComingSoonClick(e)}>
              COMING SOON...
            </Link>
          </div>
          <button onClick={() => setNflActive(!nflActive)}>
            <FaFootballBall className="icon" /> NFL
          </button>
          <div className={`submenu${nflActive ? ' active' : ''}`}>
            <Link to="/nfl/top-td-predictions" onClick={handleMenuItemClick}>
              Top TD Predictions
            </Link>
            <Link to="/nfl/top-td-longshots" onClick={handleMenuItemClick}>
              Top TD Longshots
            </Link>
            <Link to="/nfl/defensive-team-stats" onClick={handleMenuItemClick}>
              Defensive Team Stats
            </Link>
            <Link
              to="/nfl/target-shares-and-defensive-coverages"
              onClick={handleMenuItemClick}
            >
              Target Share & Defensive Coverages
            </Link>
            <Link to="/nfl/nfl-hit-rates" onClick={handleMenuItemClick}>
              NFL Hit Rates
            </Link>
          </div>
          <button onClick={() => setNhlActive(!nhlActive)}>
            <FaHockeyPuck className="icon" /> NHL
          </button>
          <div className={`submenu${nhlActive ? ' active' : ''}`}>
            <Link to="#" onClick={(e) => handleComingSoonClick(e)}>
              COMING SOON...
            </Link>
          </div>
          <button onClick={() => setToolsActive(!toolsActive)}>
            <FaTools className="icon" /> Tools
          </button>
          <div className={`submenu${toolsActive ? ' active' : ''}`}>
            <Link to="/sportsbooks/arbitrage-calculator" onClick={handleMenuItemClick}>
              Arbitrage Calculator
            </Link>
            <Link to="/sportsbooks/odds-converter" onClick={handleMenuItemClick}>
              Odds Converter
            </Link>
            <Link to="/faq" onClick={handleMenuItemClick}>
              FAQ
            </Link>
          </div>
        </div>
        <div className="content" style={{ paddingTop: '100px' }}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route
              path="/mlb/exit-velocity-tracker"
              element={<ExitVelocityTracker />}
            />
            <Route path="/mlb/whos-hot" element={<WhosHot />} />
            <Route
              path="/mlb/starting-pitcher-stats"
              element={<StartingPitcherStats />}
            />
            <Route path="/mlb/daily-lineup-stats" element={<DailyLineupStats />} />
            <Route path="/mlb/scores" element={<Scores />} />
            <Route path="/mlb/SECRETODDS" element={<MlbOdds />} />
            <Route path="/mlb/definition" element={<MLBDefinitions />} />
            <Route path="/mlb/park-factors" element={<ParkFactors />} />
            <Route path="/mlb/inning-first-stats" element={<InningFirstStats />} />
            <Route path="/mlb/the-script" element={<TopHRPredictions />} />
            <Route
              path="/sportsbooks/arbitrage-calculator"
              element={<ArbitrageCalculator />}
            />
            <Route path="/sportsbooks/odds-converter" element={<OddsConverter />} />
            <Route path="/nfl/top-td-predictions" element={<TopTDPredictions />} />
            <Route path="/nfl/top-td-longshots" element={<TopTDLongshots />} />
            <Route path="/nfl/defensive-team-stats" element={<DefensiveStats />} />
            <Route
              path="/nfl/target-shares-and-defensive-coverages"
              element={<CoverageMatrix />}
            />
            <Route path="/nfl/nfl-hit-rates" element={<NflHitRates />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/login" element={<Login />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

function Navbar({
  menuActive,
  setMenuActive,
  mlbActive,
  setMlbActive,
  nbaActive,
  setNbaActive,
  nflActive,
  setNflActive,
  nhlActive,
  setNhlActive,
  toolsActive,
  setToolsActive,
  handleComingSoonClick,
  isAuthenticated,
  setIsAuthenticated,
}) {
  const navigate = useNavigate();

  const handleTitleClick = () => {
    if (window.innerWidth <= 768) {
      navigate('/');
    }
  };

  const toggleMenu = () => {
    setMenuActive(!menuActive);
    setMlbActive(false);
    setNbaActive(false);
    setNflActive(false);
    setNhlActive(false);
    setToolsActive(false);
  };

  const handleLogout = () => {
    window.location.href = 'https://zeusanalytics.org/accounts/logout';
  };

  const handleLogin = () => {
    window.location.href = 'https://zeusanalytics.org/accounts/dubclub/login/';
  };

  return (
    <nav className="navbar">
      <div className="navbar-left">
        <Link to="/" className="navbar-item">
          <img
            src="/zeusanalyticslogo2_BG_resized.png"
            alt="Home"
            className="home-icon"
          />
        </Link>
        <span className="z-mobile" onClick={handleTitleClick}>
          Zeus Analytics
        </span>
        <span className="navbar-title">Zeus Analytics</span>
      </div>
      <div className="hamburger" onClick={toggleMenu}>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div className="navbar-right">
        <div className="dropdown">
          <button className="dropbtn">
            <FaBaseballBall className="icon" /> MLB
          </button>
          <div className="dropdown-content">
            <Link to="/mlb/the-script">The Script</Link>
            <Link to="/mlb/exit-velocity-tracker">Exit Velocity Tracker</Link>
            <Link to="/mlb/whos-hot">Who's Hot</Link>
            <Link to="/mlb/starting-pitcher-stats">Starting Pitcher Stats</Link>
            <Link to="/mlb/daily-lineup-stats">Daily Lineup Stats</Link>
            <Link to="/mlb/inning-first-stats">1st Inning Stats</Link>
            <Link to="/mlb/park-factors">Park Factors</Link>
            <Link to="/mlb/scores">Scores</Link>
            <Link to="/mlb/definition">Definitions</Link>
          </div>
        </div>
        <div className="dropdown">
          <button className="dropbtn">
            <FaBasketballBall className="icon" /> NBA
          </button>
          <div className="dropdown-content">
            <Link to="#" onClick={(e) => handleComingSoonClick(e)}>
              COMING SOON...
            </Link>
          </div>
        </div>
        <div className="dropdown">
          <button className="dropbtn">
            <FaFootballBall className="icon" /> NFL
          </button>
          <div className="dropdown-content">
            <Link to="/nfl/top-td-predictions">Top TD Predictions</Link>
            <Link to="/nfl/top-td-longshots">Top TD Longshots</Link>
            <Link to="/nfl/defensive-team-stats">Defensive Team Stats</Link>
            <Link to="/nfl/target-shares-and-defensive-coverages">
              Target Share & Defensive Coverages
            </Link>
            <Link to="/nfl/nfl-hit-rates">NFL Hit Rates</Link>
          </div>
        </div>
        <div className="dropdown">
          <button className="dropbtn">
            <FaHockeyPuck className="icon" /> NHL
          </button>
          <div className="dropdown-content">
            <Link to="#" onClick={(e) => handleComingSoonClick(e)}>
              COMING SOON...
            </Link>
          </div>
        </div>
        <div className="dropdown">
          <button className="dropbtn">
            <FaTools className="icon" /> Tools
          </button>
          <div className="dropdown-content">
            <Link to="/sportsbooks/arbitrage-calculator">Arbitrage Calculator</Link>
            <Link to="/sportsbooks/odds-converter">Odds Converter</Link>
            <Link to="/faq">FAQ</Link>
          </div>
        </div>
        {!isAuthenticated && (
          <div className="dropdown">
            <button className="dropbtn login-button" onClick={handleLogin}>
              Login
            </button>
          </div>
        )}
        {isAuthenticated && (
          <div className="dropdown">
            <button className="dropbtn login-button" onClick={handleLogout}>
              Logout
            </button>
          </div>
        )}
      </div>
    </nav>
  );
}

export default App;
